// src/AboutPage.js
import React from 'react';

const SarTimePage = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-slate-800 text-gray-100">
            <h1 className="text-5xl font-bold mb-4">SarTime Page</h1>
            <p className="text-xl mb-8">This section is currently under construction. Please check back later.</p>
            <div className="flex flex-col items-center">
                <p className="text-lg font-medium text-center">We apologize for any inconvenience. Our team is working hard to bring you this feature soon.</p>
            </div>
        </div>
    );
};

export default SarTimePage;
