import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import {  collection, where, doc, getDoc, onSnapshot, orderBy, query, limit, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

export const GlobalStateContext = createContext();

export const useGlobalContext = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [feedlots, setFeedlots] = useState([]);
  const [selectedFeedlot, setSelectedFeedlot] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [currentData2, setCurrentData2] = useState(null);
  const [realtimeData, setRealtimeData] = useState(null);
  const [unsubscribeRealtimeListener, setUnsubscribeRealtimeListener] = useState(null);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [noDataAlertRealTime, setNoDataAlertRealTime] = useState(false);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const { Feedlots, ...otherUserData } = userData;
            setUserData(otherUserData);

            if (Feedlots && Array.isArray(Feedlots)) {
              const feedlotPromises = Feedlots.map(ref => getDoc(ref));
              const feedlotDocs = await Promise.all(feedlotPromises);

              const feedlotDataArray = feedlotDocs
                .map(feedlotDoc => feedlotDoc.exists() ? feedlotDoc.data() : null)
                .filter(data => data !== null);

              setFeedlots(feedlotDataArray);
            } else {
              setError('Feedlots field is missing or not an array');
            }
          } else {
            setError('User document not found');
          }
        } catch (err) {
          setError(err.message);
        }
      } else {
        setError('No authenticated user');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate, auth]);

  const convertTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.seconds) {
      return null;
    }
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  };

  const fetchCurrentData = async (sarCompID) => {
    try {
      const additionalDataRef = doc(db, sarCompID, 'CurrentData');
      const additionalDataSnap = await getDoc(additionalDataRef);
      if (additionalDataSnap.exists()) {
        return additionalDataSnap.data();
      }
      return null;
    } catch (err) {
      throw new Error(`Failed to fetch current data for ${sarCompID}: ${err.message}`);
    }
  };

  const fetchCurrentDataTMRO = async (tMRID) => {
    try {
      const additionalTmroRef = doc(db, tMRID, 'CurrentData');
      const additionalTmroSnap = await getDoc(additionalTmroRef);
      if (additionalTmroSnap.exists()) {
        return additionalTmroSnap.data();
      }
      return null;
    } catch (err) {
      throw new Error(`Failed to fetch current data for ${tMRID}: ${err.message}`);
    }
  };

  const handleFeedlotSelect = async (event) => {
    const feedlotIndex = event.target.value;
    const feedlot = feedlots[feedlotIndex];
    setSelectedFeedlot(feedlot);

    if (unsubscribeRealtimeListener) {
      console.log('Unsubscribing from previous realtime listener');
      unsubscribeRealtimeListener();
      setUnsubscribeRealtimeListener(null);
    }

    setCurrentData(null);
    setCurrentData2(null);
    setRealtimeData(null);

    if (feedlot && feedlot.SarCompID) {
      try {
        const data = await fetchCurrentData(feedlot.SarCompID);
        setCurrentData(data);
        setupRealtimeListener(feedlot.SarCompID);
      } catch (err) {
        setError(err.message);
      }
    }

    if (feedlot && feedlot.TMRID) {
      try {
        const data2 = await fetchCurrentDataTMRO(feedlot.TMRID);
        setCurrentData2(data2);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const setupRealtimeListener = (sarCompID) => {
    setIsLoading(true); // Start loading
    const dataLoggingRef = collection(db, sarCompID, 'Archives', 'DataLogging');
    const dataLoggingQuery = query(dataLoggingRef, orderBy('timeStamp', 'desc'), limit(10));

    const unsubscribe = onSnapshot(dataLoggingQuery, (snapshot) => {
      setTimeout(() => {
        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          setRealtimeData(doc.data());
          setNoDataAlertRealTime(false);
        } else {
          setRealtimeData(null);
          setNoDataAlertRealTime(true)
        }
        setIsLoading(false); // Stop loading after delay
      }, 1000); // 1-second delay
    });

    setUnsubscribeRealtimeListener(() => unsubscribe);
  };

  const fetchWeeklyAveragesData = async (sarCompID, field) => {
    try {
      const weeklyDataRef = collection(db, sarCompID, 'Archives', 'DailyAverages');
      const weeklyDataQuery = query(weeklyDataRef, orderBy('timeStamp', 'desc'), limit(7));
      const querySnapshot = await getDocs(weeklyDataQuery);

      const timestamps = [];
      const values = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        timestamps.push(convertTimestamp(data.timeStamp)); 
        values.push(data[field]); 
      });

      return { timestamps, values };
    } catch (error) {
      console.error('Error fetching weekly averages data:', error);
      return { timestamps: [], values: [] };
    }
  };

  // const fetchSevenDayData = async (sarCompID, field) => {
  //   try {
  //     const sevenDayDataRef = collection(db, sarCompID, 'Archives', 'SevenDay_Data');
  //     const sevenDayDataQuery = query(sevenDayDataRef, orderBy('timestamp', 'desc'), limit(7));
  //     const querySnapshot = await getDocs(sevenDayDataQuery);
  
  //     const timestamps = [];
  //     const values = [];
  //     querySnapshot.forEach((doc) => {
  //       const data = doc.data();
  //       timestamps.push(convertTimestamp(data.timestamp));
  //       values.push(data[field]);
  //     });
  
  //     return { timestamps, values };
  //   } catch (error) {
  //     console.error('Error fetching seven-day data:', error);
  //     return { timestamps: [], values: [] };
  //   }
  // };

  const fetchSevenDayData = async (sarCompID, field) => {
    try {
      const sevenDayDataRef = collection(db, sarCompID, 'Archives', 'SevenDay_Data');
      const sevenDayDataQuery = query(sevenDayDataRef, orderBy('timestamp', 'desc'), limit(7));
      const querySnapshot = await getDocs(sevenDayDataQuery);
  
      const dates = [];
      const values = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        dates.push(data['Date']); // Assuming the Date field is already formatted as "mm/dd"
        values.push(data[field]);
      });
  
      return { dates, values };
    } catch (error) {
      console.error('Error fetching seven-day data:', error);
      return { dates: [], values: [] };
    }
  };
  
  


  const logReadOperation = async (tMRID, operationType, additionalInfo = {}) => {
    try {
      const logRef = collection(db, 'ReadLogs');
      await addDoc(logRef, {
        tMRID,
        operationType,
        timestamp: serverTimestamp(),
        ...additionalInfo,
      });
      console.log(`Logged ${operationType} operation for tMRID: ${tMRID}`);
    } catch (error) {
      console.error('Error logging read operation:', error);
    }
  };

  const fetchFieldsForDate = async (tMRID, date) => {
    console.log(`Fetching fields for date ${date} in feedlot ${tMRID}`);
    try {
      const unitRef = collection(db, tMRID, 'Archives', 'Unit1');
      
      // Ensure the date is treated as a number
      const numericDate = Number(date);
      
      // Query with where and orderBy
      const q = query(unitRef, where('OperDate', '==', numericDate), orderBy('OperDate', 'desc'), limit(100)); // Adjust the limit as necessary
      const querySnapshot = await getDocs(q);
  
      const fieldsData = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const filteredData = {};
  
        // Include only the required fields
        const requiredFields = ['Batch', 'Grain', 'LSCAdded', 'LSCVolume', 'WaterAdded'];
  
        requiredFields.forEach((field) => {
          if (data.hasOwnProperty(field)) {
            filteredData[field] = data[field];
          }
        });
  
        fieldsData.push({ id: doc.id, ...filteredData });
      });
      
      console.log(`Fetched fields data: ${JSON.stringify(fieldsData)}`);
      return fieldsData;
    } catch (error) {
      console.error(`Failed to fetch fields for date ${error.message}`);
      throw new Error(`Failed to fetch fields for date ${error.message}`);
    }
  };
  
  
  
  const fetchCombinedValuesForDate = (fieldsData, fieldsToSum) => {
    console.log('Calculating combined values from fields data');
  
    const combinedValues = fieldsToSum.reduce((acc, field) => {
      acc[field] = 0;
      return acc;
    }, {});
  
    fieldsData.forEach((data) => {
      fieldsToSum.forEach((field) => {
        if (data.hasOwnProperty(field)) {
          combinedValues[field] += Number(data[field]);
        }
      });
    });
  
    console.log(`Combined values: ${JSON.stringify(combinedValues)}`);
    return combinedValues;
  };
  
  
  

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate('/login');
    } catch (err) {
      console.error('Failed to logout:', err);
      setError('Failed to logout');
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <GlobalStateContext.Provider
      value={{
        userData,
        feedlots,
        selectedFeedlot,
        currentData,
        currentData2,
        realtimeData,
        error,
        isSidebarOpen,
        isSidebarCollapsed,
        isLoading,
        noDataAlertRealTime,
        handleFeedlotSelect,
        convertTimestamp,
        fetchCurrentData,
        fetchCurrentDataTMRO,
        setupRealtimeListener,
        fetchWeeklyAveragesData,
        fetchSevenDayData,
        fetchFieldsForDate,
        fetchCombinedValuesForDate,
        handleLogout,
        toggleSidebar,
        toggleCollapse,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
